import { HttpEvent, HttpHandler, HttpHeaders, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2'
import { HomeService } from '../../home/services/home.service';
import { UsuarioService } from '../services/usuario.service';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {

  constructor(
    private router: Router,
    private usuarioService: UsuarioService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

  this.usuarioService.verificarCaducudadToken();

const token: string | null = localStorage.getItem('token');
// console.log(token)
const headers: HttpHeaders | undefined = new HttpHeaders()
.append('Content-Type', 'application/json')
// .append('Access-Control-Allow-Headers', 'Content-Type')
// .append('Access-Control-Allow-Methods', 'GET, OPTIONS, POST, PUT, PATCH, DELETE')
// .append('Access-Control-Allow-Origin', '*');
 .append('authorization', `Bearer ${ token }`);
//  .append('Access-Control-Allow-Origin', '*');

// console.log('-----------ENTRA EN INTERCEPTOR-----------');

let request = req;

if (token) {
  request = req.clone({
    headers
    // setHeaders: {
    //   authorization: `Bearer ${ token }`
    // }
  });
}

  // this.usuarioService.verificarCaducudadToken();

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {

        // console.log('ERROR EN INTERCEPTOR');
        // console.log(err);

        // TODO:Mensaje de token no valido o caducado, no tiene que ser un 401 tiene que ser otro
        if (err.status === 401) {
          // console.log(err.message);

          // Swal.fire({
          //   position: 'top-end',
          //   icon: 'warning',
          //   title: 'La sesión ha expirado',
          //   html: 'Ingresa tus datos de acceso.',
          //   showConfirmButton: false,
          //   timer: 4000
          // })

          this.router.navigateByUrl('/auth/login');
        }

        // if (err.status === 404) {
        //   Swal.fire({
        //     position: 'top-end',
        //     icon: 'error',
        //     title: 'La Url de la petición no es correcta.',
        //     html: 'Avisa al administrador.',
        //     showConfirmButton: false,
        //     timer: 4000
        //   })

        // }

        return throwError( err );

      })
    );
  }


}

