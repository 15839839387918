<!-- Main content -->
<section class="content">
    <div class="error-page">
    <h2 class="headline text-warning"> 404</h2>

    <div class="error-content">
        <h3><i class="fas fa-exclamation-triangle text-warning"></i> Oops! Página no encontrada.</h3>

        <p>
        No podemos encontrar la página que estás buscando.
        Debes <a href="/avisos">volver a la página de inicio</a>.
        </p>
    </div>
    <!-- /.error-content -->
    </div>
    <!-- /.error-page -->
</section>
<!-- /.content -->
