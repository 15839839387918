import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS,  } from '@angular/common/http';
import { EnvConfigService } from './services/env-config.service';
import { environment } from 'src/environments/environment';
import * as envProd from 'src/environments/environment.prod';

export function initializeApp(configService: EnvConfigService) {
  return (): Promise<any> => {
    return configService
      .loadConfig()
      .toPromise()
      .then((config) => {
        configService.setConfig(config);
        environment.API_URL = '';
        envProd.environment.API_URL = '';
        environment.API_URL = configService.apiUrl;
        envProd.environment.API_URL = configService.apiUrl;
        environment.STORAGE_URL = configService.storageUrl;
        envProd.environment.STORAGE_URL = configService.storageUrl;
      });
  };
}


//Cambiar el Locale de la APP//
import localeEs from '@angular/common/locales/es'
import { registerLocaleData, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
///////////////////////////////
// Interceptors
import { AuthInterceptorService } from './modules/auth/interceptors/auth-interceptor.service';

import { AppRoutingModule } from './app-routing.module';
// import { CoreModule } from './core/core.module';

import { AppComponent } from './app.component';
import { NopageFoundComponent } from './core/components/nopage-found/nopage-found.component';
import { LaboratoriosComponent } from './modules/laboratorios/laboratorios.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MyPrimeNgModule } from './shared/modules/my-prime-ng/my-prime-ng.module';



registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    NopageFoundComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    AutoCompleteModule,
    MyPrimeNgModule
    // CoreModule
  ],
  providers: [
    EnvConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [EnvConfigService],
      multi: true
    },
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: 'es'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
